import React, { useState, Fragment } from 'react';
import PlayIconLight from '../../util/icons/components/PlayIconLight';
import PauseIconMedium from '../../util/icons/components/PauseIconMedium';
import Logo from '../../header/components/Logo';
import PropTypes from 'prop-types';
import GBIGradeShieldAndConceptModal
  from '../../util/logos/GBIGradeSheildAndConceptModal';
import { useSelector, useDispatch } from 'react-redux';
import { getVideoPlaying } from '../reducer';
import { setVideoPlaying } from '../actions';
import './cow-main-image.scss';
import CowMediaSelector from './CowMediaSelector';
import ChevronIcon from '../../util/icons/components/ChevronIcon';

const CowMainImage = ({ cow }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const videoPlaying = useSelector(getVideoPlaying);
  const dispatch = useDispatch();
  let imgSrc = '';
  let playIcon = null;
  // let handleClick = () => {};
  let imageSelector = null;
  let pagingIcons = null;
  const pageLeft = () => {
    if (imageIndex > 0) {
      setImageIndex(imageIndex - 1);
    }
  };
  const pageRight = () => {
    if (imageIndex < (cow.images.length - 1)) {
      setImageIndex(imageIndex + 1);
    }
  };
  if (cow.video) {
    if (videoPlaying) {
      playIcon = (
        <PauseIconMedium onClick={ () => dispatch(setVideoPlaying(false)) } />
      );
      // handleClick = () => dispatch(setVideoPlaying(false));
    } else {
      const handleClick = () => {
        dispatch(setVideoPlaying(true));
        const videoSection = document.getElementById('cow-video-section');
        if (videoSection) {
          videoSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
      playIcon = <PlayIconLight onClick={ handleClick }/>;
    }
  }
  if (cow.images && cow.images.length > 0) {
    let images = [];
    if (window.devicePixelRatio > 1 && cow.images[imageIndex].large2160) {
      imgSrc = cow.images[imageIndex].large2160;
      images = cow.images.map(i => ({
        url: i.thumb660 || i.large2160
      }));
    } else if (cow.images[imageIndex].large1080) {
      images = cow.images.map(i => ({
        url: i.thumb660 || i.large2160
      }));
      imgSrc = cow.images[imageIndex].large1080;
    }
    imageSelector = (
      <CowMediaSelector current={imageIndex}
        selectImage={ setImageIndex }
        images={ images }
        playVideo={ () => {} } />
    );
    const left = imageIndex > 0 ? 'left' : 'left hidden';
    const right = imageIndex < (cow.images.length - 1) ?
      'right' : 'right hidden';
    pagingIcons = (
      <Fragment>
        <ChevronIcon className={ left } onClick={ pageLeft } />
        <ChevronIcon className={ right } onClick={ pageRight } />
      </Fragment>
    );
  }
  if (cow.orgImage && !imgSrc) {
    if (window.devicePixelRatio > 1 && cow.orgImage.large2160) {
      imgSrc = cow.orgImage.large2160;
    } else if (cow.orgImage.large1080) {
      imgSrc = cow.orgImage.large1080;
    }
  }
  if (!imgSrc && cow.video && cow.video.poster) {
    imgSrc = cow.video.poster;
  } else if (!imgSrc && cow.orgVideo && cow.orgVideo.poster) {
    imgSrc = cow.orgVideo.poster;
  }
  const imageStyles = imgSrc ? { backgroundImage: `url('${imgSrc}')` } : null;
  const className = imgSrc ? 'image' : 'image empty';
  const logo = imgSrc ? null : <Logo noLink={ true } />;
  const gradeIcon = (cow && cow.grade && cow.grade !== 'Ungraded') ? (
    <GBIGradeShieldAndConceptModal
      score={ cow.beefQualityIndex } />
  ) : null;
  return (
    <div className="cow-main-image">
      <div className={ className }
        style={imageStyles}>
        { logo }
        { playIcon }
        { gradeIcon }
        { pagingIcons }
      </div>
      { imageSelector }
    </div>
  );
};

CowMainImage.propTypes = {
  cow: PropTypes.object.isRequired
};

export default CowMainImage;
